import { useRecommendationApi } from '@/composables/api/useRecommendationApi'
import { QueryClient, useQuery } from '@tanstack/vue-query'
import { ref } from 'vue'
import QueryKeys from '@/vue-query/QueryKeys'
import { RecommendationDto } from '@/api/contracts/models'
import { useProjectApi } from '../api/useProjectApi'

export function useRecommendationsQuery(projectId: Ref<string>) {
   const client = useProjectApi()

   const enabled = computed(() => !!projectId.value)

   return useQuery([QueryKeys.Recommendations, projectId], () => client.listRecommendations(projectId.value), { enabled: enabled, staleTime: Infinity, refetchOnWindowFocus: false})
}

export function useRecommendationsQueryPrefetch(
   queryClient: QueryClient,
   projectId: Ref<string>
): Promise<void> {
   const client = useProjectApi()
   return queryClient.prefetchQuery<RecommendationDto[]>(
      [QueryKeys.Recommendations, projectId],
      () => client.listRecommendations(projectId.value)
   )
}

import { QueryClient, useQuery } from '@tanstack/vue-query'
import QueryKeys from '@/vue-query/QueryKeys'
import { BusinessGoalDto } from '@/api/contracts/models'
import { useProjectApi } from '../api/useProjectApi'

export function useBusinessGoalsQuery(projectId: Ref<string>) {
   const client = useProjectApi()

   const enabled = computed(() => !!projectId.value);

   return useQuery([QueryKeys.BusinessGoals, projectId], () => client.listBusinessGoals(projectId.value), { staleTime: Infinity, enabled: enabled, refetchOnWindowFocus: false })
}

export function useBusinessGoalsQueryPrefetch(
   queryClient: QueryClient,
   projectId: Ref<string>
): Promise<void> {
   const client = useProjectApi()
   return queryClient.prefetchQuery<BusinessGoalDto[]>(
      [QueryKeys.BusinessGoals, projectId],
      () => client.listBusinessGoals(projectId.value)
   )
}

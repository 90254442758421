import { QueryClient, useQuery } from '@tanstack/vue-query'
import QueryKeys from '@/vue-query/QueryKeys'
import { Ref } from 'vue'
import { useBusinessGoalDataApi } from '@/composables/api/useBusinessGoalDataApi'
import { BusinessGoalDataDto } from '@/api/contracts/models'

export function useRecentBusinessGoalsDataQuery(projectId: Ref<string>)  {
   const client = useBusinessGoalDataApi()

   return useQuery([QueryKeys.RecentBusinessGoalsData, projectId], () => client.list(projectId.value))
}

export function useRecentBusinessGoalsDataQueryPrefetch(
   queryClient: QueryClient,
   projectId: Ref<string>
): Promise<void> {
   const client = useBusinessGoalDataApi()
   return queryClient.prefetchQuery<BusinessGoalDataDto[]>(
      [QueryKeys.RecentBusinessGoalsData, projectId],
      () => client.list(projectId.value)
   )
}

import { ref, Ref, watch } from 'vue'
import {
   BusinessGoalDto,
   CoreWebVitalsMetric,
   Metric
} from '@/api/contracts/models'
import { useBusinessGoalsQuery } from './queries/useBusinessGoalsQuery'
import { useRecentBusinessGoalsDataQuery } from './queries/useRecentBusinessGoalsData'
import { BusinessGoalWithData } from '@/types/types'

export function useProjectBusinessGoalsWithData(projectId: Ref<string>) {
   const { data: businessGoals } = useBusinessGoalsQuery(projectId)
   const { data: businessGoalsData } = useRecentBusinessGoalsDataQuery(projectId)

   const result = ref<undefined | BusinessGoalWithData[]>(undefined)

   const setResult = () => {
      result.value = _orderBy(
         businessGoals.value
            ?.filter((m) => m.projectId == projectId.value)
            ?.map((b) => {
               let businessGoalData = businessGoalsData.value?.filter(
                  (d) => d.businessGoalId == b.id
               )

               const orderedData = _orderBy(
                  businessGoalData,
                  (x) => x.date,
                  'desc'
               )

               const lastRecord = orderedData.length > 0 ? orderedData[0] : null

               const last3DaysAverage = _meanBy(
                  businessGoalData,
                  (d) => d.metricData?.value
               )

               const recentAverage =
                  b.metric == Metric.ThirtyDaysAvailabilityForApplication ||
                  b.metric == Metric.ThirtyDaysAvailabilityForInfrastructure
                     ? lastRecord?.metricData?.value
                     : last3DaysAverage

               return {
                  ...b,
                  recentAverage: recentAverage,
                  isRecentlyAchieved: isAchieved(b, recentAverage),
                  hasRecentAverage: recentAverage ? 1 : 0,
               }
            }),
         (x) => x.metric
      )
   }

   const isAchieved = (businessGoal: BusinessGoalDto, recentAverage: number) => {
      if (!recentAverage) {
         return false
      }

      if (
         businessGoal.metric == Metric.TTFB ||
         businessGoal.metric == Metric.TTFBFromChina ||
         businessGoal.metric == Metric.TTFBNearOrigin ||
         businessGoal.metric == Metric.GlobalNetworkOverhead ||
         businessGoal.metric == Metric.GlobalDNSResolveTime ||
         businessGoal.metric == Metric.GooglebotTTFB ||
         (businessGoal.metric == Metric.CoreWebVitals &&
            (businessGoal.coreWebVitalsMetric ==
               CoreWebVitalsMetric.CumulativeLayoutShift ||
               businessGoal.coreWebVitalsMetric ==
                  CoreWebVitalsMetric.FirstContentfulPaint ||
               businessGoal.coreWebVitalsMetric ==
                  CoreWebVitalsMetric.FirstMeaningfulPaint ||
               businessGoal.coreWebVitalsMetric ==
                  CoreWebVitalsMetric.PageWeight ||
               businessGoal.coreWebVitalsMetric ==
                  CoreWebVitalsMetric.TimeToInteractive ||
               businessGoal.coreWebVitalsMetric ==
                  CoreWebVitalsMetric.TimeToFirstByte ||
               businessGoal.coreWebVitalsMetric ==
                  CoreWebVitalsMetric.TotalBlockingTime ||
               businessGoal.coreWebVitalsMetric ==
                  CoreWebVitalsMetric.SpeedIndex ||
               businessGoal.coreWebVitalsMetric ==
                  CoreWebVitalsMetric.LargestContentfulPaint))
      ) {
         // eslint-disable-next-line
         return recentAverage < businessGoal.latestTarget?.value!
      } else {
         // eslint-disable-next-line
         return recentAverage > businessGoal.latestTarget?.value!
      }
   }

   watch(
      [businessGoals, businessGoalsData, projectId],
      () => {
         if (projectId.value && businessGoals.value && businessGoalsData.value) {
            setResult()
         }
      },
      { immediate: true, deep: true }
   )

   return result
}
